import React from 'react';

import clsx from 'clsx';

import style from './style.module.scss';

interface props {
  children?: React.ReactNode;
  className?: string;
}

const CentralFrame = ({ children, className }: props): React.JSX.Element => {
  return (
    <>
      <div className={clsx(style.frame, className)}>{children}</div>
    </>
  );
};

export default CentralFrame;
