import {
  BankHandelsbankenIcon,
  BankLansforsakringarIcon,
  BankNoreaIcon,
  BankSEBIcon,
  BankSwedIcon
} from 'shared-components/assets/icons';

export interface BaseProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

export interface BankProps {
  name: string;
  icon: string;
  healthy?: boolean;
}

export enum Banks {
  UNSET,
  REVOLUT,
  MANDATO
}

export const BankList: BankProps[] = [
  { name: 'seb', icon: BankSEBIcon, healthy: undefined },
  {
    name: 'swedbank',
    icon: BankSwedIcon,
    healthy: undefined
  },
  {
    name: 'handelsbank',
    icon: BankHandelsbankenIcon,
    healthy: undefined
  },
  {
    name: 'nordea',
    icon: BankNoreaIcon,
    healthy: undefined
  },
  {
    name: 'lansforsakringar',
    icon: BankLansforsakringarIcon,
    healthy: undefined
  }
];
