import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { BankID } from 'shared-components/components/BankID';
import { useGateway } from 'providers/GatewayProvider';
import BlueFrame from 'shared-components/components/BlueFrame';
import { usePrevious } from 'utils';
import { useSWCallbackSignEvent } from 'shared-components/providers/SWEventProvider';
import { SEvent } from 'utils/stateMachine';

export interface BankProps {
  name: string;
  icon: string;
}

export const SignTransaction = () => {
  const {
    values: { token, authMode, amount, currency, recipientName, recipientIban, stateRef },
    setters: { setToken }
  } = useGateway();
  const [progress, setProgress] = useState(0);
  const [buttonText, setButtonText] = useState('Pay');

  const prevToken = usePrevious(token);

  useEffect(() => {
    if (prevToken && prevToken.length > 0 && token.length === 0) {
      setProgress(1);
    } else {
      setProgress(0);
    }
  }, [prevToken, token]);

  useSWCallbackSignEvent((data: any) => {
    const signEventData = data.piSignState;

    if (signEventData === undefined) {
      return;
    }

    if (signEventData.status == 'recipMissing') {
      stateRef.send({ type: SEvent.ADD_RECIPIENT });
    }

    if (signEventData.status === 'requestAgain') {
      setToken('');
      setButtonText('Pay');
      setProgress(0);
    }

    if (signEventData.status === 'authReqd' && token !== signEventData.token) {
      setToken(signEventData.token);
      setProgress(0);
    }
  });

  return (
    <>
      <BlueFrame style={{ padding: '24px' }}>
        {token.length > 0 && (
          <>
            <Row style={{ marginBottom: '8px', fontSize: 18 }}>
              <Col>Amount to pay</Col>
            </Row>
            <Row style={{ marginBottom: '14px', fontWeight: 700, fontSize: 24 }}>
              <Col>
                {currency} {amount.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
              </Col>
            </Row>
            <Row style={{ marginBottom: '8px', fontSize: 18 }}>
              <Col>{recipientName}</Col>
            </Row>
            <Row style={{ marginBottom: '30px', fontSize: 12 }}>
              <Col>{recipientIban}</Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <BankID
              token={token}
              type={authMode}
              buttonDescription={buttonText}
              progress={progress}
              onSetProgress={(value: number) => setProgress(value)}
            />
          </Col>
        </Row>
      </BlueFrame>
    </>
  );
};

/*
THE OLD STUFF:
      <div style={{ display: 'flex', justifyContent: 'center', gap: 10 }}>
        {coreData.values.bank && <div className={style.itemValue}>{coreData.values.bank}</div>}
        {coreData.values.account && <div className={style.itemValue}>{coreData.values.account.iban}</div>}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <center>
          <div>
            <br />
            <CountdownCircleTimer
              isPlaying={isStartCountDown}
              duration={60}
              colors={['#2293FB', '#2293FB']}
              colorsTime={[7, 0]}
              onComplete={() => {
                // do your stuff here
                // console.log("onComplete");
              }}
            >
              {({ remainingTime }) => <div style={{ fontSize: 31, color: '#2293FB' }}>{remainingTime}s</div>}
            </CountdownCircleTimer>
          </div>
        </center>
      </div>
*/
