import React from 'react';

import { BaseProps } from 'utils/const';

import lstyle from './style.module.scss';

const BlueFrame = ({ children, style }: BaseProps) => {
  return (
    <>
      <div className={lstyle.f} style={style}>
        {children}
      </div>
    </>
  );
};

export default BlueFrame;
