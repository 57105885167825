export const environment = process.env.REACT_APP_ENV as string;
export const githash = process.env.REACT_APP_FE as string;
export const apiHost = process.env.REACT_APP_API_HOST as string;
export const merchantportalHost = process.env.REACT_APP_MERCHANTPORTAL_HOST as string;
export const graphqlHost = process.env.REACT_APP_GRAPHQL_HOST as string;
export const settleHost = process.env.REACT_APP_SETTLE_HOST as string;
export const twitterLink = process.env.REACT_APP_TWITTER as string;
export const githubLink = process.env.REACT_APP_GITHUB as string;
export const telegramLink = process.env.REACT_APP_TELEGRAM as string;
export const email = process.env.REACT_APP_EMAIL as string;
