import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import CentralFrame from 'shared-components/components/CentralFrame';
import { githash, Method, Service } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { useServiceWorkerStatus } from 'shared-components/providers/ServiceWorkerStatusProvider';
import { GatewayState, useGateway } from 'providers/GatewayProvider';
import { BoxContainer } from 'components/PaymentFlow/components/BoxContainer';
import {
  useSWCallbackAccountEvent,
  useSWCallbackErrorEvent,
  useSWCallbackSelectedEvent,
  useSWCallbackSignEvent,
  useSWCallbackStateEvent,
  useSWCallbackUpdateEvent
} from 'shared-components/providers/SWEventProvider';
import { isValidUrl } from 'shared-components/utils';
import { StateDataType } from 'shared-components/types/Notifications';
import { stateResolver, usePrevious } from 'utils';
import { useHash } from 'shared-components/hooks/useHash';
import { BankList } from 'utils/const';
import { cancelOperation, getBankingHealth } from 'queries/queries';
import { SEvent, State } from 'utils/stateMachine';

import { GatewayWidget } from './GatewayWidget';
import { Success } from './Success';
import { Fail } from './Fail';

let pingTimer: any;

const title = {
  [State.UNKNOWN]: '',
  [State.IDLE]: '',
  [State.PRE_BANK_HEALTH_CHECK]: '',
  [State.BANK_HEALTH_CHECK]: '',
  [State.PID_DISCOVERY]: 'PID Discovery',

  [State.SELECT_BANK]: 'Which of these banks do you wish to pay with?',
  [State.REQUESTED]: 'Which of these banks do you wish to pay with?',
  [State.RESOLVE_MERCHANT_BY_ID]: 'Which of these banks do you wish to pay with?',

  [State.BANK_AUTHENTICATE]: 'Authenticate',
  [State.MONITOR_AUTH]: 'Authenticate',
  [State.AUTH_USER_INTO]: 'Authenticate',

  [State.ACCOUNT_CHOOSE]: 'Which account do you wish to pay with?',
  [State.LIST_ACCOUNTS]: 'Which account do you wish to pay with?',
  [State.SELECT_USER_ACCOUNT]: 'Which account do you wish to pay with?',

  [State.ADD_RECIPIENT]: 'Add Recipient',

  [State.SIGN]: 'Sign the payment',
  [State.SIGN_TX]: 'Sign the payment',
  [State.MONITOR_SIGN_TX]: 'Sign the payment',

  [State.NEED_UPGRADE]: 'Upgrade account',
  [State.SUCCESS]: '',
  [State.ERROR]: ''
};

const description = {
  [State.PRE_BANK_HEALTH_CHECK]: 'Checking connections',
  [State.BANK_HEALTH_CHECK]: 'Checking connections',
  [State.UNKNOWN]: '',
  [State.IDLE]: 'Checking connections',
  [State.PID_DISCOVERY]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.SELECT_BANK]: '',
  [State.RESOLVE_MERCHANT_BY_ID]: 'Querying merchant',
  [State.REQUESTED]: 'Connecting to selected bank',

  [State.BANK_AUTHENTICATE]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.MONITOR_AUTH]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.AUTH_USER_INTO]: 'Authenticating user into bank',

  [State.ACCOUNT_CHOOSE]: '',
  [State.LIST_ACCOUNTS]: 'Downloading account data',
  [State.SELECT_USER_ACCOUNT]: 'Select account to pay',

  [State.ADD_RECIPIENT]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.SIGN]: isMobile ? 'Open BankID on your device by pressing the button' : 'Start the BankID app and scan the QR',

  [State.SIGN_TX]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.MONITOR_SIGN_TX]: isMobile
    ? 'Open BankID on your device by pressing the button'
    : 'Start the BankID app and scan the QR',

  [State.NEED_UPGRADE]:
    'To add a new recipient or continue with Payment Initation, activate your Mobile BankID for extended use.',

  [State.SUCCESS]: '',
  [State.ERROR]: ''
};

const GatewayData = () => {
  const { authToken, refId, depositAmount, bankData } = useParams();
  const [step, setStep] = useState(0);
  const coreData = useGateway();
  const swStatus = useServiceWorkerStatus();
  const gqlPrevStatus = usePrevious(swStatus.graphqlConnected);
  const { hash, updateHash } = useHash();

  useEffect(() => {
    updateHash('initiated');
    coreData.setters.setRecipientName('BlockSettle AB');
    coreData.setters.setRecipientIban('SE2723000000023101729376');
    coreData.values.stateRef.send({ type: SEvent.NEXT });
  }, [hash]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    coreData.setters.setAuthMode(isMobile ? 'ast' : 'qr');
  }, [coreData.setters]);

  useEffect(() => {
    if (refId !== undefined) {
      coreData.setters.setRefId(refId);
    }
  }, [coreData.setters, refId]);

  useEffect(() => {
    const amount = Number.parseFloat(depositAmount ?? '0');
    coreData.setters.setAmount(amount);
  }, [depositAmount]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (bankData !== undefined) {
      coreData.setters.setPid(bankData);
    }
  }, [bankData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (authToken !== undefined) {
      coreData.setters.setAuthToken(authToken);
    }
  }, [authToken, coreData.setters]);

  const bankHealth = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'getBankingHealth',
    data: {},
    auto: false,
    onResponse: (data: any) => {
      try {
        const banks = data;
        for (const element of banks) {
          const id = BankList.findIndex((bank) => element.bank === bank.name);
          BankList[id].healthy = element.healthy;
        }
        const hasTrue = BankList.find((bank) => bank.healthy === true);
        if (hasTrue === undefined) {
          coreData.setters.setErrorString('Service unavailable. Please try again later');
          coreData.values.stateRef.send({ type: SEvent.BANKS_UNHEALTHY });
        } else {
          coreData.values.stateRef.send({ type: SEvent.BANKS_HEALTHY });
        }
      } catch (error) {
        console.log(error);
      }
    },
    onErrors: (data: any) => {
      console.log(data);
    }
  });

  const ping = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'nonexistent',
    data: {
      query: { ping: true }
    },
    auto: false
  });

  const _cancelPayment = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'cancelPay',
    data: {
      query: cancelOperation(coreData.values.operationId)
    },
    auto: false,
    onResponse: (data: any) => {
      if (data.status === true) {
        coreData.setters.setErrorString('Payment process cancelled');
      }
    }
  });

  useEffect(() => {
    if (coreData.values.state === State.BANK_HEALTH_CHECK) {
      bankHealth.execute({
        query: getBankingHealth()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coreData.values.state]);

  useEffect(() => {
    switch (coreData.values.state) {
      case State.IDLE: {
        // Reset all locks
        break;
      }
      case State.PID_DISCOVERY: {
        setStep(1);
        // Do the PID DISCOVERY WIDGET
        break;
      }

      case State.SELECT_BANK: {
        setStep(2);
        break;
      }

      case State.RESOLVE_MERCHANT_BY_ID:
      case State.REQUESTED: {
        break;
      }

      case State.MONITOR_AUTH:
      case State.AUTH_USER_INTO:
      case State.BANK_AUTHENTICATE: {
        setStep(3);
        // bank authenticate screen
        break;
      }

      case State.LIST_ACCOUNTS:
      case State.SELECT_USER_ACCOUNT:
      case State.ACCOUNT_CHOOSE: {
        coreData.setters.setToken('');
        setStep(4);
        break;
      }
      case State.SIGN_TX:
      case State.MONITOR_SIGN_TX:
      case State.SIGN: {
        setStep(5);
        // sign the payment
        break;
      }
      case State.SUCCESS: {
        //        setStep(6);
        // payment success
        break;
      }
      /*
      case GatewayState.ERROR:
        const url = new URL(settleReturnFailureUrl);
        if (coreData.values.errorString.length > 0) {
          url.searchParams.set('errorString', coreData.values.errorString);
        }
        window.location.href = url.toString();
        break;
        */

      default: {
        break;
      }
    }
    // STATE MACHINE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bankData, coreData.values.state, depositAmount, coreData.values.systemReady]);

  useEffect(() => {
    if (swStatus.graphqlConnected === true && refId !== undefined) {
      if (pingTimer === undefined) {
        pingTimer = setInterval(() => {
          ping.execute();
        }, 15_000);
      }
    } else {
      clearTimeout(pingTimer);
      pingTimer = undefined;
      if (swStatus.graphqlConnected !== gqlPrevStatus) {
        coreData.setters.setErrorString('Connection with gateway closed.');
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      }
    }
  }, [
    swStatus.graphqlConnected,
    refId,
    ping,
    coreData.values.state,
    coreData.setters,
    gqlPrevStatus,
    coreData.values.stateRef
  ]);

  useSWCallbackAccountEvent((data: any) => {
    console.log('ACCOUNT');
    console.log(data);
    if (data?.task === coreData.values.operationId) {
      coreData.setters.setAccountList(data.accounts);
    }
  });

  useSWCallbackSelectedEvent((value: any) => {
    const data = value.selectAccount;
    if (coreData.values.state === State.BANK_AUTHENTICATE) {
      coreData.values.stateRef.send({ type: SEvent.COMPLETED });
    }
    if (data?.operationId === coreData.values.operationId) {
      const accounts = data.accounts;
      const selected = data.selected;
      coreData.setters.setAccountList(accounts);
      if (selected) {
        coreData.setters.setAccount(selected);
      }
    }
  });

  useSWCallbackErrorEvent((data: any) => {
    if (data.cancelOperation === null) {
      console.log(data);
    } else {
      coreData.values.stateRef.send({ type: SEvent.FAIL });
    }
  });

  useSWCallbackSignEvent((data: any) => {
    if (coreData.values.state === State.NEED_UPGRADE) {
      return;
    }

    if (data !== undefined && data?.operationId === coreData.values.operationId) {
      const signEventData = data.piSignState;
      if (signEventData === undefined) {
        return;
      }
      if (signEventData.status == 'accepted') {
        //        coreData.values.stateRef.send({ type: SEvent.NEXT });
        //coreData.setters.setState(GatewayState.SIGN);
        //      window.location.href = isValidUrl(signEventData?.url) ? signEventData?.url : settleReturnSuccessUrl;
      }
      if (signEventData.status == 'requested') {
        //      window.location.href = isValidUrl(signEventData?.url) ? signEventData?.url : settleReturnSuccessUrl;
      }
      if (signEventData.status == 'authReqd') {
        if (signEventData.token.length > 300) {
          coreData.setters.setToken(`data:image/png;base64,${signEventData.token}`);
        } else {
          coreData.setters.setToken(signEventData.token);
        }
      }

      if (signEventData.status == 'recipMissing') {
        coreData.values.stateRef.send({ type: SEvent.ADD_RECIPIENT });
      }
      if (signEventData.status === 'requestAgain') {
        coreData.setters.setToken('');
        coreData.values.stateRef.send({ type: SEvent.NEXT });
      }

      if (signEventData.status == 'completed') {
        coreData.values.stateRef.send({ type: SEvent.COMPLETED });
      }

      if (signEventData.status == 'authDone' && coreData.values.bank === 'seb') {
        coreData.setters.setErrorString('Payment processing in progress, you may return to the merchant site');
        coreData.values.stateRef.send({ type: SEvent.COMPLETED });
      }

      if (signEventData.status == 'authDone' && coreData.values.authMode === 'qr') {
        coreData.setters.setToken('');
      }
      if (signEventData.status == 'needUpgrade') {
        const url = signEventData.url;
        if (isValidUrl(url)) {
          coreData.setters.setRedirectUrl(url);
          coreData.values.stateRef.send({ type: SEvent.NEED_UPGRADE });
          //          coreData.setters.setState(GatewayState.NEED_UPGRADE);
        }
      }
      if (signEventData.status == 'requestAgain') {
        // DO SOME MESSAGE LIKE "PI is about to resume"
      }
      if (signEventData.status == 'failed') {
        coreData.values.stateRef.send({ type: SEvent.FAIL });
        //        coreData.setters.setState(GatewayState.ERROR);
      }
    }
  });

  useSWCallbackUpdateEvent((data: any) => {
    if (coreData.values.state === State.NEED_UPGRADE) {
      return;
    }

    if (data !== undefined && data?.operationId === coreData.values.operationId) {
      const authState = data.piAuthState;
      if (authState.state === 'timedOut') {
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      }
      if (authState.state === 'pending') {
        //        coreData.setters.setState(GatewayState.MONITOR_AUTH);
        if (authState.token.length > 0) {
          coreData.setters.setToken(authState.token);
        } else if (authState.token.length === 0 && authState.base64Data.length > 0) {
          coreData.setters.setToken(`data:image/png;base64,${authState.base64Data}`);
          coreData.setters.setAuthMode('image');
        } else {
          if (coreData.values.authMode === 'qr') {
            coreData.setters.setToken('');
          }
        }
      }
      if (authState.state === 'rejected') {
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      }
      if (authState.state === 'success') {
        coreData.values.stateRef.send({ type: SEvent.COMPLETED });
      }
    }
  });

  useSWCallbackStateEvent((data: StateDataType) => {
    const operationProgress = data.operationProgress;
    const state = operationProgress.state;
    const currentTask = operationProgress.currentTask;
    const paymentState = operationProgress.paymentState;

    if (
      coreData.values.state === State.NEED_UPGRADE ||
      coreData.values.state === State.SUCCESS ||
      coreData.values.state === State.ERROR ||
      paymentState === 'needUpgrade'
    ) {
      return;
    }
    if (data !== undefined && data?.operationId === coreData.values.operationId) {
      if (state === 'failed' || state === 'cancelled') {
        coreData.setters.setErrorString(operationProgress.errorStr);
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      }
      if (/monitor (\w+) sign tx/.test(currentTask) && paymentState !== 'redo') {
        coreData.values.stateRef.send({ type: SEvent.NEXT });
      }
      const serverState = stateResolver(operationProgress);
      if (serverState.state !== GatewayState.UNKNOWN) {
        coreData.setters.setErrorString(serverState.errorMsg);
        //coreData.setters.setState(serverState.state);
      }
    }
  });

  switch (coreData.values.state) {
    case State.SUCCESS: {
      return (
        <center>
          <CentralFrame>
            <center>
              <Success />
            </center>
          </CentralFrame>
        </center>
      );
    }
    case State.ERROR: {
      return (
        <center>
          <CentralFrame>
            <center>
              <Fail />
            </center>
          </CentralFrame>
        </center>
      );
    }
    default: {
      return (
        <>
          <center>
            <CentralFrame>
              <BoxContainer
                maxSteps={5}
                step={step}
                onBack={() => {
                  //                  cancelPayment.execute();
                  coreData.values.stateRef.send({ type: SEvent.BACK });
                  //coreData.values.stateRef.send({ type: SEvent.CANCEL });

                  /*
                  switch (coreData.values.state) {
                    case GatewayState.SELECT_BANK: {
                      coreData.setters.setState(GatewayState.ERROR);
                      break;
                    }
                    case GatewayState.SELECT_USER_ACCOUNT:
                    case GatewayState.LIST_ACCOUNTS:
                    case GatewayState.ACCOUNT_CHOOSE: {
                      coreData.setters.setState(GatewayState.SELECT_BANK);
                      break;
                    }
                  }
                  */
                }}
                title={title?.[coreData.values.state]}
                desc={description?.[coreData.values.state]}
              >
                <GatewayWidget />
              </BoxContainer>
            </CentralFrame>
            <div>rev: {githash}</div>
          </center>
        </>
      );
    }
  }
};

export default GatewayData;
