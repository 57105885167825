import { useEffect, useRef } from 'react';

import { GatewayState } from 'providers/GatewayProvider';
import { OperationProgressType, OperationStateEnum } from 'shared-components/types/Notifications';

export const paymentStateSuccessResolver = (state: string) => {
  switch (state) {
    case 'success':
    case 'completed':
    case 'awaitingFinality': {
      return true;
    }

    default: {
      return false;
    }
  }
};

export const paymentStateFailResolver = (state: string) => {
  switch (state) {
    case 'cancelled': {
      return true;
    }

    default: {
      return false;
    }
  }
};

export const stateStringtoEnum = (state: string, paymentStatus: string) => {
  if (state === 'resolve merchant by id') {
    return GatewayState.RESOLVE_MERCHANT_BY_ID;
  }
  if (state === 'select user account') {
    return GatewayState.SELECT_USER_ACCOUNT;
  }
  if (/monitor (\w+) auth/.test(state)) {
    /*
    if (paymentStateFailResolver(paymentStatus)) {
      return GatewayState.ERROR;
    }*/
    return GatewayState.MONITOR_AUTH;
  }
  if (/auth user into (\w+)/.test(state)) {
    return GatewayState.AUTH_USER_INTO;
  }
  if (/list (\w+) accounts/.test(state)) {
    return GatewayState.LIST_ACCOUNTS;
  }
  if (/sign (\w+) tx/.test(state)) {
    if (paymentStateSuccessResolver(paymentStatus)) {
      return GatewayState.SUCCESS;
    }
    return GatewayState.SIGN_TX;
  }
  if (/monitor (\w+) sign tx/.test(state)) {
    if (paymentStateSuccessResolver(paymentStatus)) {
      return GatewayState.SUCCESS;
    }
    return GatewayState.MONITOR_SIGN_TX;
  }
  return GatewayState.UNKNOWN;
};

export const stateResolver = (operationProgress: OperationProgressType) => {
  let state = GatewayState.UNKNOWN;
  let errorMsg = '';

  switch (operationProgress.state) {
    case OperationStateEnum.running: {
      state = stateStringtoEnum(operationProgress.currentTask, operationProgress.paymentState);
      break;
    }

    case OperationStateEnum.failed:
    case OperationStateEnum.cancelled: {
      if (operationProgress.errorStr.includes('needUpgrade')) {
        //
      } else {
        state = GatewayState.ERROR;
        errorMsg = operationProgress.errorStr;
      }

      break;
    }

    //case OperationStateEnum.unknown:
    case OperationStateEnum.success: {
      state = GatewayState.SUCCESS;
      break;
    }
    default: {
      // Do nothing here
      break;
    }
  }

  return { state, errorMsg };
};

export const usePrevious = (value: any) => {
  const ref = useRef<any>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};
