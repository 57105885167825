import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';

import { useGateway } from 'providers/GatewayProvider';
import Icon from 'shared-components/components/Icon';
import { Service, Method, partnerId } from 'shared-components/configuration';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { BankList } from 'utils/const';
import { cancelOperation, getLiveOperationsForPid, initiatePayment } from 'queries/queries';
import { SEvent, State } from 'utils/stateMachine';

import style from './style.module.scss';

export const SelectBank = () => {
  const coreData = useGateway();
  const [operations, setOperations] = useState(-1);
  const [operationsDone, setOperationsDone] = useState(-1);

  const handleInitPaylinkErrors = useCallback(
    (data: any) => {
      const logs = data[0];
      if (logs) {
        switch (logs?.message) {
          case 'KYC_ERROR': {
            coreData.setters.setErrorString('There was a problem with your KYC');
            break;
          }
          case 'RFQ_ERROR': {
            coreData.setters.setErrorString('RFQ already used. Please try again with new payment');
            break;
          }
          case 'PAY_LINK_ERROR': {
            coreData.setters.setErrorString('Problem communicating with bank');
            break;
          }
          default: {
            coreData.setters.setErrorString(logs?.message);
          }
        }
      }
      coreData.values.stateRef.send({ type: SEvent.FAIL });
    },
    [coreData.setters, coreData.values]
  );

  const cancelPayment = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: '',
    data: {
      query: cancelOperation(coreData.values.operationId)
    },
    auto: false,
    onResponse: (data: any) => {
      if (data.data.cancelOperation === null && data.data.success === true) {
        setOperationsDone((old) => old + 1);
      }
    }
  });

  const liveOperations = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'getLiveOperationsForPid',
    data: {
      query: getLiveOperationsForPid(coreData.values.pid)
    },
    auto: false,
    onResponse: (data: any) => {
      setOperations(data.length);
      setOperationsDone(0);
      for (const element in data) {
        cancelPayment.execute({ query: cancelOperation(data[element].id) });
      }
    }
  });

  const query = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'initiatePayment',
    data: {},
    auto: false,
    onResponse: (data: any) => {
      coreData.setters.setOperationId(data);
      coreData.values.stateRef.send({ type: SEvent.NEXT });
    },
    onErrors: handleInitPaylinkErrors
  });

  const onBankClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
      const bank = event.currentTarget.value;
      coreData.setters.setBank(bank);
      if (coreData.values.pid === undefined || coreData.values.pid.length === 0) {
        coreData.setters.setErrorString('Unexpected error: PersonID is empty');
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      } else {
        query.execute({
          query: initiatePayment({
            merchantId: partnerId,
            personId: coreData.values.pid,
            bank: bank,
            amount: coreData.values.amount.toString(),
            currency: 'sek',
            reference: coreData.values.refId,
            authMode: coreData.values.authMode,
            clientIp: '127.0.0.1'
          })
        });
      }
      coreData.values.stateRef.send({ type: SEvent.NEXT });
    },
    [coreData.setters, coreData.values, query]
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        onClick={() => {
          setOperations(0);
          setOperationsDone(0);
          liveOperations.execute();
        }}
      >
        Cancel all pending operations {operations > -1 && `(${operationsDone} / ${operations})`}
      </Button>
      <br />
      {BankList.map((bank, key) => {
        return (
          <Button
            key={key}
            value={bank.name}
            disabled={
              bank.healthy === undefined || bank.healthy === false || coreData.values.state !== State.SELECT_BANK
            }
            variant="outline-primary"
            className={style.bankButton}
            onMouseUp={onBankClick}
            onTouchEnd={onBankClick}
          >
            <Icon icon={bank.icon} />
          </Button>
        );
      })}
    </div>
  );
};
