import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

import { BankID } from 'shared-components/components/BankID';
import Icon from 'shared-components/components/Icon';
import { BankIDIcon } from 'shared-components/assets/icons';
import { useGateway } from 'providers/GatewayProvider';
import { environment } from 'configuration';
import { usePrevious } from 'utils';
import { SEvent } from 'utils/stateMachine';

export const BankAuthenticate = () => {
  const coreData = useGateway();
  const [progress, setProgress] = useState(0);
  const prevToken = usePrevious(coreData.values.token);

  useEffect(() => {
    if (prevToken && prevToken.length > 0 && coreData.values.token.length === 0) {
      setProgress(1);
    }
  }, [prevToken, coreData.values.token]);

  const sebSandboxAuth = useCallback(() => {
    const payload = {
      start_token: coreData.values.token,
      personal_identity_number: coreData.values.pid
    };
    fetch('https://trysettle-api-staging.blocksettle.com/api/v1/seb_mock', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    }).then((response) => {
      if (response.status === 404) {
        coreData.values.stateRef.send({ type: SEvent.FAIL });
      }
    });
  }, [coreData.values.pid, coreData.values.stateRef, coreData.values.token]);

  return environment === 'staging' && coreData.values.bank === 'sebzzzz' ? (
    <>
      <center>
        <Button onClick={sebSandboxAuth}>Mock auth SEB</Button>
      </center>
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <Icon icon={BankIDIcon} />
        <p style={{ marginBottom: 0, marginTop: 10 }}>Open BankID on your device by pressing the button</p>
      </div>
    </>
  ) : (
    <>
      <center>
        <BankID
          token={coreData.values.token}
          type={coreData.values.authMode}
          progress={progress}
          onSetProgress={(value: any) => setProgress(value)}
        />
      </center>
      <div style={{ textAlign: 'center', marginTop: 30 }}>
        <p style={{ marginBottom: 0, marginTop: 10 }}>Open BankID on your device by pressing the button</p>
      </div>
    </>
  );
};
