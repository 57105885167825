import { createBrowserRouter } from 'react-router-dom';
import React from 'react';

import TestPage from 'pages/test';
import Status from 'pages/status';
import Layout from 'components/Layout';
import { environment } from 'configuration';
import TrySettle from 'pages/trysettle';

import GatewayData from './pages/gateway-data';

export const routerConfig = [
  {
    path: '/',
    Component: Layout,
    children: [
      {
        path: '/test',
        element: <TestPage />
      },

      {
        path: '/payment-status/:depositRef',
        element: <Status />
      },
      {
        path: '/status/:depositRef',
        element: <Status />
      },
      {
        path: '/:authToken/:refId/:depositAmount',
        element: <GatewayData />
      },
      {
        path: '/:authToken/:refId/:depositAmount/:bankData',
        element: <GatewayData />
      }
    ]
  }
];

export const partnerRouterConfig = [
  {
    path: '/',
    Component: Layout,
    children: [
      {
        path: '',
        element: <TrySettle />
      },
      {
        path: '/:refId/:depositAmount',
        element: <GatewayData />
      },
      {
        path: '/:refId/:depositAmount/:bankData',
        element: <GatewayData />
      }
    ]
  }
];
console.log(environment);
const router = environment === 'partner' ? createBrowserRouter(partnerRouterConfig) : createBrowserRouter(routerConfig);

export default router;
