import * as jose from 'jose';

const isObject = (obj: any) => obj && typeof obj === 'object';

export const noop = (..._params: any[]) => {
  // Do nothing
};

export const mergeDeep = (...objects: any) => {
  // eslint-disable-next-line unicorn/no-array-reduce
  return objects.reduce((prev: any, obj: any) => {
    if (obj) {
      for (const key of Object.keys(obj)) {
        const pVal = prev[key];
        const oVal = obj[key];

        if (Array.isArray(pVal) && Array.isArray(oVal)) {
          prev[key] = [...pVal, ...oVal];
        } else if (isObject(pVal) && isObject(oVal)) {
          prev[key] = mergeDeep(pVal, oVal);
        } else {
          prev[key] = oVal;
        }
      }
    }

    return prev;
  }, {});
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    noop(error);
    return false;
  }
};

export const cryptoKeyPairGenerate = async () => {
  const { publicKey, privateKey } = await jose.generateKeyPair('ES256', {
    extractable: true
  });
  const pubKey = await jose.exportSPKI(publicKey);
  const privKey = await jose.exportPKCS8(privateKey);
  return { pubKey, privKey };
};
export const signData = async (privateKey: string, data: string) => {
  const key = await jose.importPKCS8(privateKey, 'ES256');
  const jws = await new jose.CompactSign(new TextEncoder().encode(data)).setProtectedHeader({ alg: 'ES256' }).sign(key);
  return jws;
};
