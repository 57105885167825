import React from 'react';

import { PersonIDDiscovery } from 'shared-components/components/BankID';
import { SelectBank } from 'pages/gateway-data/SelectBank';
import { useGateway } from 'providers/GatewayProvider';
import WaitSpinner from 'shared-components/components/WaitSpinner';
import { SEvent, State } from 'utils/stateMachine';

import { BankAuthenticate } from './BankAuthenticate';
import { SelectAccount } from './SelectAccount';
import { SignTransaction } from './SignTransaction';
import { NeedUpgrade } from './NeedUpgrade';
import { AddRecipient } from './AddRecipient';

export const GatewayWidget = () => {
  const coreData = useGateway();

  switch (coreData.values.state) {
    case State.PRE_BANK_HEALTH_CHECK:
    case State.BANK_HEALTH_CHECK: {
      return <WaitSpinner>Checking banks connectivity status</WaitSpinner>;
    }
    case State.PID_DISCOVERY: {
      return (
        <>
          <PersonIDDiscovery
            onSuccess={(data: any) => {
              coreData.setters.setPid(data.person_id);
              coreData.values.stateRef.send({ type: SEvent.PID_DISCOVERY_SUCCESS });
            }}
            onFail={(data: any) => {
              coreData.setters.setErrorString(data.errorMsg);
              coreData.values.stateRef.send({ type: SEvent.PID_DISCOVERY_FAIL });
            }}
          />
        </>
      );
    }

    case State.RESOLVE_MERCHANT_BY_ID:
    case State.REQUESTED:
    case State.SELECT_BANK: {
      return <SelectBank />;
    }

    case State.MONITOR_AUTH:
    case State.AUTH_USER_INTO:
    case State.BANK_AUTHENTICATE: {
      return <BankAuthenticate />;
    }

    case State.LIST_ACCOUNTS:
    case State.SELECT_USER_ACCOUNT:
    case State.ACCOUNT_CHOOSE: {
      return <SelectAccount />;
    }

    case State.SIGN_TX:
    case State.MONITOR_SIGN_TX:
    case State.SIGN: {
      return <SignTransaction />;
    }

    case State.ADD_RECIPIENT: {
      return <AddRecipient />;
    }
    case State.NEED_UPGRADE: {
      return <NeedUpgrade link={coreData.values.redirectUrl} />;
    }
    case State.SUCCESS: {
      // payment success
      break;
    }
    case State.ERROR: {
      // payment error
      break;
    }

    default: {
      break;
      // just ignore
    }
  }
  return <></>;
};
/*

    {authorized && currentStep === 1 && (

      )}
      {authorized &&
        currentStep === 2 &&
        (coreData.values.bank === 'seb' && environment === 'staging' ? (
          <Button onClick={sebSandboxAuth}>Authorize bankid</Button>
        ) : (
          <BankAuthenticate
            maxStep={4}
            step={2}
            token={token}
            authMode={coreData.values.authMode}
            onAuthenSuccess={() => {
              setPaymenData((pre) => ({
                ...pre,
                authBank: true
              }));
              confirmAccountQuery.execute({ variables: {} });
              onNextStep();
            }}
            onBack={onPreviousStep}
          />
        ))}
      {authorized && currentStep === 3 && (
        <SelectAccount
          maxStep={4}
          step={3}
          accounts={accounts.acounts}
          onSelectAccount={(account) => {
            confirmAccountQuery.execute({
              variables: {
                balance: account.balance,
                currency: account.currency,
                iban: account.iban,
                task: accounts.task
              }
            });
            setPaymenData((pre) => ({
              ...pre,
              account: account
            }));
            onNextStep();
          }}
          onBack={onPreviousStep}
        />
      )}
      {authorized && currentStep === 4 && (
        <SignTransaction maxStep={4} step={4} value={paymenData} onBack={() => { console.log('prevstep')}} />
      )}

      
*/
