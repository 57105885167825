export enum OperationStateEnum {
  unknown = 'unknown',
  success = 'success',
  running = 'running',
  failed = 'failed',
  cancelled = 'cancelled'
}

export type OperationProgressType = {
  id: string;
  state: OperationStateEnum;
  paymentState: string;
  currentTask: string;
  errorStr: string;
};

export type PiAuthStateType = {
  state: string;
  token: string;
  base64Data: string;
};

export type PiSignStateType = {
  payId: string;
  url: string;
  status: string;
};

export type StateDataType = {
  operationProgress: OperationProgressType;
  operationId: string;
  merchantId: string;
};

export type AccountsDataType = {
  iban: string;
  balance: string;
  currency: string;
};

export type UpdateDataType = {
  piAuthState: PiAuthStateType;
  operationId: string;
  merchantId: string;
};

export type SignDataType = {
  piSignState: PiSignStateType;
  operationId: string;
  merchantId: string;
};

export type AccountDataType = {
  task: string;
  acounts: AccountsDataType[];
};

export type NotificationType = {
  notify_type: string;
  data: StateDataType | UpdateDataType | SignDataType | AccountDataType | any;
  operationId?: string;
  merchantId?: string;
};
