import React from 'react';
import { Spinner } from 'react-bootstrap';

import { BaseProps } from 'shared-components/utils/base_interface';

const WaitSpinner = ({ children }: BaseProps) => {
  return (
    <>
      <center>
        <Spinner style={{ height: '62px', width: '62px', color: '#2293FB', fontSize: '24px' }} />
        <br />
        {children}
      </center>
    </>
  );
};

export default WaitSpinner;
