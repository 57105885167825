import React, { forwardRef } from 'react';
import { FloatingLabel, Form, InputGroup } from 'react-bootstrap';

import { Sek } from 'shared-components/assets/icons';
import Icon from 'shared-components/components/Icon';

import style from './style.module.scss';

interface props {
  onChange?: (event: any) => void;
  name: string;
}

const BoxedTextInput = forwardRef((props: props, ref: any) => {
  return (
    <>
      <div>
        <InputGroup className="mt-3">
          <FloatingLabel controlId="contactSalesMessage" label="Enter amount" className={style.flabel}>
            <Form.Control
              ref={ref}
              autoFocus
              name={props.name}
              type="number"
              placeholder="Message"
              className={style.input}
              onChange={props.onChange}
              step={0.01}
              defaultValue={2}
              required
            />
            <Form.Control.Feedback type="invalid">Enter valid amount</Form.Control.Feedback>
          </FloatingLabel>
          <InputGroup.Text className={style.suffix}>
            <Icon icon={Sek} className={style.suffiximg} />
            SEK
          </InputGroup.Text>
        </InputGroup>
      </div>
    </>
  );
});

export default BoxedTextInput;
