import React from 'react';

import clsx from 'clsx';

import style from './style.module.scss';

interface props {
  icon: string;
  alt?: string;
  className?: string;
}
const Icon = ({ alt, icon, className }: props): React.JSX.Element => {
  return icon.length > 0 ? <img alt={alt} src={icon} className={clsx(style.icon, className)} /> : <></>;
};

export default Icon;
