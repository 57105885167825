import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button, FloatingLabel, Form, InputGroup, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import CentralFrame from 'shared-components/components/CentralFrame';
import CopyButton from 'shared-components/components/CopyButton';
import CircleText from 'components/CircleText';
import BoxedTextInput from 'components/BoxedTextInput';
import ErrorModal from 'components/Error';
import { useGateway } from 'providers/GatewayProvider';
import { SEvent } from 'utils/stateMachine';

import style from './style.module.scss';

const TrySettle = () => {
  const {
    values: { stateRef }
  } = useGateway();
  const inputReference = createRef<any>();
  const pidRef = useRef<HTMLInputElement>(null);
  const navigator = useNavigate();
  const [busy, setBusy] = useState(false);
  const [error, setError] = useState(false);
  const [action, setAction] = useState(-1);
  const [shortUrl, setShortUrl] = useState('');
  const [pidVisible, setPidVisible] = useState<boolean>(false);
  const buttonText = useMemo(() => {
    if (busy) {
      return <Spinner />;
    }
    return 'Make Payment';
  }, [busy]);

  const formSubmit = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      const target = e.target as typeof e.target & {
        reference: { value: string };
        amount: { value: string };
        pid: { value: string };
      };

      if (Number.isNaN(Number.parseFloat(target.amount.value))) {
        console.log('ERROR HERE');
        return;
      }
      setBusy(true);
      const pid = target?.pid?.value;
      setBusy(false);
      const generatedUrl =
        pid !== undefined && pid?.length > 0
          ? `/${target.reference.value}` +
            `/${target.amount.value}` +
            `/${target.pid.value}?redirect=${window.location}`
          : `/${target.reference.value}` + `/${target.amount.value}?redirect=${window.location}`;

      if (action === 1) {
        stateRef.send({ type: SEvent.NEXT });
        navigator(generatedUrl);
      } else if (action === 2) {
        fetch(`https://tinyurl.com/api-create.php?url=${generatedUrl}`).then(async (response) => {
          setShortUrl(await response.text());
        });
      }
    },
    [action, navigator, stateRef]
  );

  useEffect(() => {
    if (pidVisible) {
      pidRef?.current?.focus();
    }
  }, [pidVisible]);

  return (
    <>
      <ErrorModal
        show={error}
        handleClose={() => {
          setError(false);
        }}
      />
      <center className="mt-5">
        <h1 className={style.h1}>Try Settlement and donate to a good cause</h1>
        <p className={style.secondline}>
          Make a quick and secure donation directly from your bank account using open
          <br /> banking- no fees attached.
        </p>
        <CentralFrame>
          <Form onSubmit={formSubmit}>
            <CircleText blueText="1">Enter reference</CircleText>
            <FloatingLabel controlId="reference" label="Reference number">
              <Form.Control
                type="text"
                size="sm"
                placeholder="Reference number"
                name="reference"
                defaultValue={'BS12345678'}
              />
            </FloatingLabel>

            <div className={style.visible}>
              <CircleText blueText="2">Choose an amount</CircleText>
              <BoxedTextInput ref={inputReference} name={'amount'} />
              <br />
              <Form.Check // prettier-ignore
                type="switch"
                id="pidCheckbox"
                label="Provide PersonID data"
                name="pidenable"
                checked={pidVisible}
                onClick={(e) => {
                  setPidVisible(e.currentTarget.checked);
                }}
              />
              {pidVisible === true && (
                <FloatingLabel controlId="pid" label="Person ID">
                  <Form.Control type="text" size="sm" placeholder="Person ID" name="pid" ref={pidRef} />
                </FloatingLabel>
              )}
              <div className={style.info}>
                By using the service, I accept Blocksettle Terms of Use, and confirm that I have read Blocksettle
                Privacy Statement.
              </div>
              <center>
                <Button
                  variant="primary"
                  name="shortUrl"
                  value={1}
                  size="sm"
                  type="submit"
                  disabled={busy}
                  onClick={() => {
                    setAction(2);
                  }}
                  style={{ width: '140px' }}
                >
                  Create Link
                </Button>

                <Button
                  variant="primary"
                  name="pay"
                  value={1}
                  size="sm"
                  type="submit"
                  disabled={busy}
                  onClick={() => {
                    setAction(1);
                  }}
                  style={{ width: '140px', margin: '5px' }}
                >
                  {buttonText}
                </Button>
              </center>
              {shortUrl && (
                <>
                  <InputGroup className="mt-3">
                    <FloatingLabel controlId="shorturl" label="Payment link" className={style.flabel}>
                      <Form.Control autoFocus type="text" value={shortUrl} />
                    </FloatingLabel>
                    <InputGroup.Text className={style.suffix}>
                      <CopyButton value={shortUrl} />
                    </InputGroup.Text>
                  </InputGroup>
                  <br />
                </>
              )}
            </div>
          </Form>
        </CentralFrame>
      </center>
    </>
  );
};
export default TrySettle;
