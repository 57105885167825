import React, { useCallback, useState } from 'react';
import { Button } from 'react-bootstrap';

import clsx from 'clsx';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';

import { CopyIcon } from 'shared-components/assets/icons';

import mstyle from './style.module.scss';

interface props {
  value?: string;
  className?: string;
  style?: React.CSSProperties;
  isBlack?: boolean;
}
const CopyButton = ({ className, style, value, isBlack }: props): React.JSX.Element => {
  const [isShow, setShow] = useState(false);
  const handleCopy = useCallback(async () => {
    setShow(true);
    await navigator.clipboard.writeText(value || '').then(() => {
      setTimeout(() => setShow(false), 1000);
    });
  }, [value]);
  return (
    <OverlayTrigger
      placement={'top'}
      show={isShow}
      overlay={
        <Popover
          id={`popover-contained`}
          className={mstyle.popover}
          arrowProps={{
            style: {
              display: 'none'
            }
          }}
        >
          <Popover.Body className={`px-2 py-1 ${isBlack ? 'text-black' : 'text-white'}`}>
            <span>copied</span>
          </Popover.Body>
        </Popover>
      }
    >
      <Button variant="link" onClick={handleCopy} onKeyDown={handleCopy}>
        <img alt={'copy'} src={CopyIcon} className={clsx(mstyle.icon, className)} style={style} />
      </Button>
    </OverlayTrigger>
  );
};

export default CopyButton;
