import { Outlet } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import React from 'react';

import clsx from 'clsx';

import style from './style.module.scss';

const Layout = () => {
  return (
    <>
      <div className={clsx('d-flex', 'flex-column', style.content)} style={{}}>
        <Container className="flex-grow-1 z-3 mb-4">
          <Outlet />
        </Container>
      </div>
    </>
  );
};

export default Layout;
