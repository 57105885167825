import React from 'react';
import { Button as ButtonRB, ButtonProps } from 'react-bootstrap';

import clsx from 'clsx';

import { BaseProps } from '../../utils/base_interface';

import style from './style.module.scss';

interface props extends BaseProps, ButtonProps {
  className?: string;
  ref?: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null | undefined;
}
const Button = ({ children, className, ...rest }: props): React.JSX.Element => {
  return (
    <ButtonRB {...rest} className={clsx(style.button, className)}>
      {children}
    </ButtonRB>
  );
};

export default Button;
