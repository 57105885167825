import React, { useCallback } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { useGateway } from 'providers/GatewayProvider';
import useSWQuery from 'shared-components/hooks/useSWQuery';
import { Method, Service } from 'shared-components/configuration';
import WaitSpinner from 'shared-components/components/WaitSpinner';
import { selectAccount } from 'queries/queries';

import style from './style.module.scss';

export interface AccountProps {
  iban: string;
  balance: string;
  currency: string;
}

export const SelectAccount = () => {
  const coreData = useGateway();

  const confirmAccountQuery = useSWQuery({
    service: Service.GRAPHQL,
    method: Method.QUERY,
    returnObjectName: 'confirmAccount',
    data: {},
    auto: false
  });

  const onAccountClick = useCallback(
    (account: AccountProps) => {
      coreData.setters.setAccount(account);
      confirmAccountQuery.execute({
        query: selectAccount({
          operationId: coreData.values.operationId,
          balance: account.balance,
          currency: account.currency,
          iban: account.iban
        })
      });
    },
    [confirmAccountQuery, coreData.setters, coreData.values.operationId]
  );

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {coreData.values.accountList.length > 0 ? (
          coreData.values.accountList.map((account: any, key) => {
            return (
              <Button
                key={key}
                variant="outline-primary"
                className={style.accountButton}
                disabled={account.balance < coreData.values.amount}
                style={{
                  background: account.iban === coreData.values.account.iban ? 'rgba(34, 147, 251, 0.35)' : undefined
                }}
                onMouseUp={() => onAccountClick(account)}
                onTouchEnd={() => onAccountClick(account)}
              >
                <Row style={{ margin: '0px' }}>
                  <Col xs={12} sm={9} className={style.accountName}>
                    {account.name}
                    <br />
                    {account.iban}
                  </Col>
                  <Col xs={12} sm={3} className={style.accountBalance}>
                    {account.balance} {account.currency?.toUpperCase()}
                  </Col>
                </Row>
              </Button>
            );
          })
        ) : (
          <WaitSpinner>Loading account data</WaitSpinner>
        )}
      </div>
    </>
  );
};
