type InitiatePaymentProps = {
  merchantId: string;
  personId: string;
  bank: string;
  amount: string;
  currency: string;
  reference: string;
  authMode: string;
  clientIp: string;
};

type SelectAccountProps = {
  iban: string;
  balance: string;
  currency: string;
  operationId: string;
};
export const getBankingHealth = () => ({
  source: 'orchestrator',
  request: { get_banking_health: null }
});

export const initiatePayment = ({
  merchantId,
  personId,
  bank,
  amount,
  currency,
  reference,
  authMode,
  clientIp
}: InitiatePaymentProps) => ({
  source: 'orchestrator',
  request: {
    initiatePayment: {
      merchantId,
      personId,
      bank,
      amount,
      currency,
      reference,
      authMode,
      clientIp
    }
  }
});

export const getLiveOperationsForPid = (pid: string) => ({
  source: 'orchestrator',
  request: {
    getLiveOperationsForPid: pid
  }
});

export const operationState = (operationId: string) => ({
  source: 'orchestrator',
  request: {
    operationState: operationId
  }
});

export const cancelOperation = (operationId: string) => ({
  source: 'orchestrator',
  request: {
    cancelOperation: operationId
  }
});

export const selectAccount = ({ iban, balance, currency, operationId }: SelectAccountProps) => ({
  source: 'merchant',
  operationId,
  response: {
    selectAccount: {
      iban,
      balance,
      currency
    }
  }
});
