import { loggerHost } from '../configuration';

export const WebsocketLogger = () => {
  //const [ready, setReady] = useState(false);
  const socket = new WebSocket(loggerHost);

  const sendLog = (message: string) => {
    //      if (ready) {
    if (socket.readyState === socket.OPEN) {
      socket.send(message);
    } else {
      console.log('log socket not connected');
    }
  };

  socket.addEventListener('open', (event: any) => {
    //    setReady(true);
    console.log(event);
  });
  socket.addEventListener('close', (event: any) => {
    //    setReady(false);
    console.log(event);
  });
  socket.addEventListener('message', (event: any) => {
    console.log(event);
  });

  return { socket, sendLog };
};
